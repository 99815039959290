import React, {useState, useEffect} from 'react';

import LayoutPanel from "components/layout/layoutPanel";
import Link from 'components/common/Link';
import Carousel from 'components/common/Carousel';

import { mermaidFlowmojiBgUrl } from 'constants/urls';

import fetchBlogData from 'constants/fetchBlogData';

function BlogCarousel (props) {

	const [blogPosts, setPosts] = useState([]);
	const hidePanelImgBg = props.hidePanelImgBg ? true : false;
	const panelBg = props.panelBg ? props.panelBg : 'mer-theme--light mer-bg--ui-light';
	const panelSpacing = props.panelSpacing ? props.panelSpacing : 'mer-py-lg';

    useEffect(() => {

    	let apiQuery = (props.apiQuery) ? props.apiQuery : '';
		const blogData = (props.blogData) ? props.blogData : '';

        const fetchData = async () => {
            setPosts( await fetchBlogData(`/apis/seahorse/blogs/${apiQuery}`));
        };
		
		if (blogData) {
			setPosts(blogData);
		} else {
        	fetchData();
		}
    }, []);

	return (

		<>
			{ !!(Array.isArray(blogPosts) && blogPosts.length && blogPosts.length>0) &&
				<>
					<LayoutPanel background={panelBg}
	                    		 padding={panelSpacing}
	                    		 border={props.panelBorder}
	                    		 inlineStyle={{ backgroundImage: hidePanelImgBg ? '' : `url(${mermaidFlowmojiBgUrl})`,
	                    		 				backgroundRepeat: hidePanelImgBg ? '' : 'repeat',
	                    		 				backgroundSize: hidePanelImgBg ? '' : '60vh' }}>
						{props.title && 
							<div className="container">
								<div className="row justify-content-center">
									<div className={`${props.titleCol ? props.titleCol : 'col-5'} content mer-text--align-center`}>
										<h3 className={`${props.titleHeadingClass ? props.titleHeadingClass : ''} mb-0`}>{props.title}</h3>

										{props.description &&
											<div>{props.description}</div>
										}
									</div>
								</div>
							</div>
						}

		                <Carousel equalHeight={true} 
		                          data={blogPosts}
								  merBtnGrpClass={props.merBtnGrpClass}
								  merBtnLinkClass={props.merBtnLinkClass}
								  slidesToShow={props.slidesToShow}/>

		                {props.ctaText !== undefined && props.ctaLink !== undefined && 
		                	<div className="container">
			                    <div className={`${props.ctaContainerClass ? props.ctaContainerClass : 'row justify-content-center mer-pt-de'}`}>
			                        <div className={`${props.ctaClass ? props.ctaClass : 'col-10 content text-center'}`}>
										<h5 className={`${props.ctaHeadingClass ? props.ctaHeadingClass : ''}`}>
											<Link text={`${props.ctaText ? props.ctaText : 'Find more stories on our blog'}`} 
												link={`${props.ctaLink ? props.ctaLink : '/blog'}`}
												linkClass={`${props.ctaLinkClass ? props.ctaLinkClass : ''}`}
												linkTarget={`${props.ctaLinkTarget ? props.ctaLinkTarget : ''}`}
											/>
										</h5>
			                        </div>
			                    </div>
			                </div>
		                }
		            </LayoutPanel>
		        </>
			}
		</>
	);
}

export default BlogCarousel;