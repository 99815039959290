
import Axios from 'axios';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const getVideoID = (url) =>{
    if(url.toLowerCase().indexOf('youtube')>0){
        return getYoutubeID(url);
    }else if(url.toLowerCase().indexOf('vimeo')>0){
        return '';
    }
    return null;
}

const getYoutubeID = (url) => {
    var r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

    r = url.match(rx);
    // console.log('youtubeId');
    // console.log(r);
    if(r!==undefined && r!==null && r.length>1){
        return r[1];
    }
    return null;
}

const getVimeoID = (url) =>{
    var r, rx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/;
    r = url.match(rx);
    if(r!==undefined && r!==null && r.length>4){
        return r[5];
    }
    return null;
}

// // ----------------------
// // RUNTIME DATA FETCHING
// // ----------------------

const fetchBlogData = async (url) => {

    try {
        const resultData = await Axios(url); 

        // console.log(resultData);
        let transformedDataArray = []; 
        resultData.data.forEach(data => {
            let newData = {
                title: data.post_title,
                subtitle: data.term ? capitalize(data.term) : capitalize(data.category_names[0]),
                // subtitle: capitalize(data.categories[0]),
                link: data.link,
                ctaText: 'Read more',
                description: data.post_excerpt,
                mediaType: data.format !== 'standard' && data.video_url !== undefined ? 'video' : data.format === 'standard' && data.image_url !== null ? 'photo' : null,
                mediaPlatform: data.format !== 'standard' && data.video_url !== undefined && data.video_url !==null ? data.video_url.toLowerCase().indexOf('vimeo')>0 ? 'vimeo' : 'youtube' : null,
                mediaSrc: data.format !== 'standard' && data.video_url !== undefined  && data.video_url!==null ? data.video_url.toLowerCase().indexOf('vimeo')>0 ? getVimeoID(data.video_url) : getVideoID(data.video_url) : data.image_url !== null ? data.image_url : null,
            };
            transformedDataArray.push(newData);
        });

        return transformedDataArray;

    } catch (error) {
        console.log('Error returning blog contents')
    }

}


export default fetchBlogData;