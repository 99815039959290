import React, { useState, useEffect, useRef } from 'react';
import Link from 'components/common/Link';
import Video from 'components/common/Video';
import { Svg } from 'kh-common-components';
import {getYoutubeImageUrl, getYoutubeVideoUrl, getVimeoVideoUrl, getVimeoVideoDetail} from 'constants/urls';
import Axios from 'axios';

function MediaCard(props) {
    const [videoPlay, setVideoPlay] = useState(false);
    const [vimeoImageUrl, setVimeoImageUrl] = useState('');
    
    const isYoutubeVideo = (props.mediaPlatform!==undefined && props.mediaPlatform!==null && props.mediaPlatform.toUpperCase()==='YOUTUBE');
    const isVimeoVideo = (props.mediaPlatform!==undefined && props.mediaPlatform!==null && props.mediaPlatform.toUpperCase()==='VIMEO');
    const imageUrl = isYoutubeVideo? getYoutubeImageUrl(props.mediaSrc) : null;
    const videoUrl = isYoutubeVideo? getYoutubeVideoUrl(props.mediaSrc) : null;
    const vimeoVideoUrl =  isVimeoVideo? getVimeoVideoUrl(props.mediaSrc) : null;

    let hideDescription = props.hideDescription !== undefined ? props.hideDescription : true;
    let hideCta = props.hideCta !== undefined ? props.hideCta : true;

    // card container class
    const defaultCardContainerClass = `mer-card mer-card-media mdc-card`;
    let cardHeight100Class = props.isCardSameHeight !== undefined && props.isCardSameHeight === true ? ` mer-card--height-100` : '';
    let cardContainerClass = `${defaultCardContainerClass}${cardHeight100Class}`

    // video container class
    const defaultVideoContainerClass = `mer-card__video mdc-card__media`;
    const defaultImageContainerClass = `mer-card__video mdc-card__media`;
    let disableWidescreen = props.disableWidescreen !== undefined && props.disableWidescreen === true ? '' : ' mdc-card__media--16-9';
    let videoContainerClass = `${defaultVideoContainerClass}${disableWidescreen}`;

    const merBtnGrpClass = props.merBtnGrpClass ? props.merBtnGrpClass : 'justify-content-end';
    const merBtnLinkClass = props.merBtnLinkClass ? ` ${props.merBtnLinkClass}` : '';

    // orientation
    // default: carousel
    let orientation = props.orientation !== undefined ? props.orientation : 'carousel';

    // if(isYoutubeVideo){
    //     console.log('youtube');
    //     console.log(videoUrl);
    // }
    
    const imageClick = () =>{
        setVideoPlay(true);
    }

    const fetchData = async () => {
        await fetchVimeoVideoData(props.mediaSrc);
    };

    const mediaCardRef = useRef(null);

    useEffect(() => {
        if (orientation !== 'carousel') {
            mediaCardRef.current.className = cardContainerClass
        } else {
            mediaCardRef.current.parentElement.className = cardContainerClass
        }
        fetchData();
    }, []);

    const fetchVimeoVideoData = async (videoId) => {
        if(isVimeoVideo!==undefined && isVimeoVideo!==null && isVimeoVideo &&
           videoId!==undefined && videoId!==null && videoId.replace(/ /g, '') !== '' && vimeoImageUrl===''){
            try {
                const url =getVimeoVideoDetail(videoId);
                const videoData = await Axios(url); 
                // console.log('video data')
                // console.log(videoData);
                setVimeoImageUrl(videoData.data[0].thumbnail_large);
                return videoData;
        
            } catch (error) {
                console.log('Error getting video details');
            }
        }
        return null;
    }
      
    return (
        <div ref={mediaCardRef} className="mer-card-media-react__container">
            {
               props.mediaType === 'video' && !videoPlay && !isVimeoVideo &&
                <div className={videoContainerClass} style={{'backgroundImage': `url(${imageUrl})`}} onClick={() => imageClick()} role="button" tabIndex={0}>
                    <div className="mdc-card__media-content mer-card__media-content--play">
                        <Svg title="Play video" 
                             icon="play" />
                    </div>
                </div>
            }

            {
               props.mediaType === 'video' && !videoPlay && isVimeoVideo &&
                <div className={videoContainerClass} style={{'backgroundImage': `url(${vimeoImageUrl})`}} onClick={() => imageClick()} role="button" tabIndex={0}>
                    <div className="mdc-card__media-content mer-card__media-content--play">
                        <Svg title="Play video" 
                             icon="play" />
                    </div>
                </div>
            }

            {
                props.mediaType === 'video' && videoPlay && !isVimeoVideo &&
                <div className={videoContainerClass}>
                    <Video videoSrcURL={videoUrl} videoTitle={props.title} />
                </div>
            }

            {
                props.mediaType === 'video' && videoPlay && isVimeoVideo &&
                <div className={videoContainerClass}>
                    <Video videoSrcURL={vimeoVideoUrl} videoTitle={props.title} />
                </div>
            }

            {
                props.mediaType === 'photo' && props.id !== 'helpCentre' && !props.hideCta &&
                    <a href={props.link} alt={props.title} className="mdc-card__media mer-card__media-link mdc-card__media--16-9" style={{'backgroundImage': `url(${props.mediaSrc})`}}></a>
            }

            {
                props.mediaType === 'photo' && props.id !== 'helpCentre' && props.hideCta &&
                    <div className="mdc-card__media mer-card__media-link mdc-card__media--16-9" style={{'backgroundImage': `url(${props.mediaSrc})`}}></div>
            }

            {
                props.mediaType === 'square' && props.id !== 'helpCentre' && props.hideCta &&
                    <div className={`mdc-card__media mdc-card__media--square ${props.mediaCardLink ? 'mer-card__media-link' : ''}`} style={{'backgroundImage': `url(${props.mediaSrc})`}}></div>
            }

            { (props.subtitle || props.title) && 
                <div className="mer-card-primary-action">
                    {props.subtitle &&
                        <h5 className="mer-card__subtitle">{props.subtitle}</h5>
                    }

                    {props.title &&
                        <h4 className="mer-card__title">
                            {!props.hideCta &&
                                <Link text={props.title}
                                    link={props.link}
                                    emptyclass="true" />
                            }
                            {props.hideCta &&
                                props.title
                            }
                        </h4>
                    }


                    {!!(props.id !== 'helpCentre' || props.id === 'helpCentre' & props.mediaType !== 'video') && !props.hideDescription &&
                        <div className="mer-card__body">
                            <p className="mer-card__body-text">{props.description}</p>
                        </div>
                    }
                </div>
            }

            {!!(props.id !== 'helpCentre' || props.id === 'helpCentre' & props.mediaType !== 'video') && !props.hideCta &&
                <div className="mer-card-action-container mdc-card__actions">
                    <div className={`mer-button-group ${merBtnGrpClass}`}>
                        <Link text={props.ctaText}
                              link={props.ctaLink ? props.ctaLink : props.link}
                              linkClass={`mer-button mer-button--secondary mdc-card__action${merBtnLinkClass}`} />
                    </div>
                </div>
            }
        </div>
    );
}

export default MediaCard;