import React from 'react';
import Slider from 'react-slick';
import MediaCard from 'components/common/MediaCard';


function Carousel (props) {
// console.log(props);
    let defaultCarouselClass = 'mer-media-card-carousel mer-media-card-carousel-react';
    let carouselIsHeightEqual = props.equalHeight !== undefined && props.equalHeight  ? ' mer-slick-slider--height-equal' : '';
    let carouselClass = defaultCarouselClass + carouselIsHeightEqual;
    let carouselSettingInfinite = false;

    let isRunTime = props.isRunTime;
    let relatedMediaCarousel = null;
    let displaySlides = props.slidesToShow !== undefined && props.slidesToShow ? parseInt(props.slidesToShow) : 5;
    let useLazyLoad = displaySlides >= 5 ? true : false;

    if ( isRunTime!==undefined && isRunTime!==null && isRunTime===true)
    {
      //  relatedMediaCarousel = props.data.content.filter(x => x.id === mediaCarouselId);
    } else {
        relatedMediaCarousel = props.data;

        let relatedMediaCarouselItems = props.data.length;

        if (relatedMediaCarouselItems > 4) {
            carouselSettingInfinite = true;
        }
    }

    const settings = {
        // rows: 0, // causes memory leak
        // lazyLoad: useLazyLoad, 
        arrows: false,
        dots: true,
        infinite: carouselSettingInfinite,
        swipeToSlide: true,
        draggable: true,

        speed: 800,
        slidesToShow: displaySlides,

        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '128px',
  
        dotsClass: 'd-flex slick-dots',
        className: carouselClass,

        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '96px'
                }
            },
            {
                breakpoint: 1680,
                settings: {
                    infinite: true,
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '96px'
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    infinite: true,
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '96px'
                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: true,
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '48px'
                }
            },
            {
                breakpoint: 640,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '128px'
                }
            },
            {
                breakpoint: 512,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '96px'
                }
            },
            {
                breakpoint: 448,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '48px'
                }
            }
        ]
    };

    return (
        <React.Fragment>

            <Slider {...settings}>
               {relatedMediaCarousel !== undefined && relatedMediaCarousel!==null && relatedMediaCarousel &&
                    props.data.map((media, index) => {
                        return (
                            <MediaCard key={index}
                               {...media}
                               id={props.id}
                               merBtnGrpClass={props.merBtnGrpClass}
                               merBtnLinkClass={props.merBtnLinkClass}
                            />
                        )
                    })
                }
                {
                    relatedMediaCarousel===null &&
                    props.data.map((media,index) =>{
                        return (
                            <MediaCard key={index}
                                {...media}
                                id={props.id}
                                merBtnGrpClass={props.merBtnGrpClass}
                                merBtnLinkClass={props.merBtnLinkClass}
                            />
                        )
                    })
                }
               
            </Slider>
        </React.Fragment>
    );
}

export default Carousel;